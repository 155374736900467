<template lang="html">
  <section class="editorial-team">
    <div class="container">

      <template v-if="entry">
        <div @click="goToBack()" class="btn-back" title="Volver a la página anterior">Volver</div>
        <h1 class="title-cat-big">{{entry.name}}</h1>
        <EditorJs :object="entry.body" />
      </template>

    </div>
  </section>
</template>

<script lang="js">
  import store from '@/store';
  import {
    mapGetters,
    mapActions
  } from 'vuex';
  import EditorJs from "@/components/editorJs.vue";
  export default {
    name: 'editorial-team',
    props: [],
    components: {
      EditorJs
    },
    mounted() {
      this.loadPageFixed({
        slug: this.$route.path.replace('/', '')
      });

    },
    data() {
      return {

      }
    },
    methods: {
      ...mapActions({
        loadPageFixed: 'contents/loadPageFixed',
      }),
      goToBack() {
        this.$router.back()
      }
    },
    computed: {
      entry() {
        return store.getters['contents/getPageFixed'](this.$route.path.replace('/', ''))
      }

    }
  }
</script>

